import React from 'react';
import PropTypes from 'prop-types';

export const Container = ({ className, fluid, children }) => (
  <div
    className={`${className ? className + ' ' : ''}container${
      fluid ? '-fluid' : ''
    }`}
  >
    {children}
  </div>
);

Container.defaultProps = {
  fluid: false,
};

Container.prototype = {
  /**
   * Extra class in container
   */
  className: PropTypes.string,
  /**
   * Fluid container if it is true
   */
  fluid: PropTypes.bool.isRequired,
  /**
   * Content in container
   */
  children: PropTypes.element.isRequired,
};
