import React, { useEffect } from 'react';
import { createContext, useReducer, useContext } from 'react';

import * as ACTION from './action';
import * as UniversityContextUtils from './university-context-utils';
import * as UnirefApi from '../../services/api/api';
import { STRUCTURES } from '../../services/api/api';

const UniversitiesContext = createContext();

const eventsReducer = (state, action) => {
  switch (action.type) {
    case ACTION.CHANGE_PAGE: {
      return {
        ...state,
        blogs: {
          ...state.blogs,
          page: action.page,
          contents: null,
          allPages: null,
        },
      };
    }
    case ACTION.LOADED_PAGE: {
      return {
        ...state,
        blogs: {
          ...state.blogs,
          contents: action.contents,
          allPages: action.allPages,
        },
      };
    }
    case ACTION.LOAD_GLOSSARY_BLOG: {
      return {
        ...state,
        selectedBlog: {
          id: action.eventId,
          structureId: STRUCTURES.glossaryblog,
          content: null,
          error: false,
        },
      };
    }
    case ACTION.LOAD_RESTAURANTS_BLOG: {
      return {
        ...state,
        selectedBlog: {
          id: action.eventId,
          structureId: STRUCTURES.restaurantsblog,
          content: null,
          error: false,
        },
      };
    }
    case ACTION.LOAD_UNIVERSITY_BLOG: {
      return {
        ...state,
        selectedBlog: {
          id: action.eventId,
          structureId: STRUCTURES.universityblog,
          content: null,
          error: false,
        },
      };
    }
    case ACTION.LOAD_UNIVERSITY_SIMPLE_BLOG: {
      return {
        ...state,
        selectedBlog: {
          id: action.eventId,
          structureId: STRUCTURES.universitySimpleBlog,
          content: null,
          error: false,
        },
      };
    }
    case ACTION.LOADED_BLOG: {
      return {
        ...state,
        selectedBlog: {
          ...state.selectedBlog,
          content: action.content,
          error: false,
        },
      };
    }
    case ACTION.LOADED_BLOG_EXCEPTION: {
      return {
        ...state,
        selectedBlog: {
          ...state.selectedBlog,
          error: true,
        },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const UniversitiesProvider = ({ initialValues, children }) => {
  const [state, dispatch] = useReducer(eventsReducer, {
    blogs: UniversityContextUtils.calcUniversitiesInitialValues(initialValues),
    selectedBlog: UniversityContextUtils.calcUniversityInitialValues(initialValues),
  });
  const value = { state, dispatch };

  /**
   * Load blogs.
   */
  useEffect(() => {
    if (state.blogs.page !== null) {
      const fetchEvents = async () => {
        const limit = UnirefApi.BLOG_LIMIT;
        const apiResponse = await UnirefApi.getUniversitiesPage(
          state.blogs.page,
          limit
        );
        const allPages = Math.ceil(apiResponse.allCounts / limit);
        dispatch({
          type: ACTION.LOADED_PAGE,
          contents: apiResponse.list,
          allPages: allPages,
        });
      };
      fetchEvents();
    }
  }, [state.blogs.page, state.blogs.structure]);

  /**
   * Load selected blog.
   */
  useEffect(() => {
    if (
      state.selectedBlog.id &&
      state.selectedBlog.id !== state.selectedBlog?.content?.id
    ) {
      const fetchEvent = async () => {
        try {
          let selectedBlog = state.blogs?.contents
            ? state.blogs.contents.find(
                (blog) => +blog.id === +state.selectedBlog.id
              )
            : null;
          if (!selectedBlog) {
            selectedBlog = await UnirefApi.getBlogContent(
              state.selectedBlog.structureId,
              state.selectedBlog.id
            );
          }
          dispatch({ type: ACTION.LOADED_BLOG, content: selectedBlog });
        } catch (ex) {
          dispatch({ type: ACTION.LOADED_BLOG_EXCEPTION });
        }
      };
      fetchEvent();
    }
  }, [state.selectedBlog.structureId, state.selectedBlog.id]);



  return (
    <UniversitiesContext.Provider value={value}>{children}</UniversitiesContext.Provider>
  );
}

export const useUniversities = () => {
  const context = useContext(UniversitiesContext);
  if (context === undefined) {
    throw new Error('useUniversities must be used within a UniversitiesProvider');
  }
  return context;
};
