/**
 * Action to load the selected blogs page.
 */
export const CHANGE_PAGE = 'changePage';
export const LOADED_PAGE = 'loadedPage';

/**
* Success loading movie or simple blog content.
*/
export const LOADED_BLOG = 'loadedBlog';

/**
* Failed loading movie or simple blog content.
*/
export const LOADED_BLOG_EXCEPTION = 'loadedBlogException';

export const LOAD_GLOSSARY_BLOG = 'loadDGlossaryBlog';
export const LOAD_RESTAURANTS_BLOG = 'loadRestaurantsBlog';
export const LOAD_UNIVERSITY_BLOG = 'loadUniversityBlog';
export const LOAD_UNIVERSITY_SIMPLE_BLOG = 'loadUniversitySimpleBlog';

/**
* Change page (reload the blogs in reducer)
* @param {Object} dispatch object
* @param {Number} page selected page
*/
export const changePage = (dispatch, page) => {
  dispatch({ type: CHANGE_PAGE, page: page });
};

export const changeGlossaryBlog = (dispatch, id) => {
  dispatch({ type: LOAD_GLOSSARY_BLOG, eventId: id });
};

export const changeRestaurantsBlog = (dispatch, id) => {
  dispatch({ type: LOAD_RESTAURANTS_BLOG, eventId: id });
};

export const changeUniversityBlog = (dispatch, id) => {
  dispatch({ type: LOAD_UNIVERSITY_BLOG, eventId: id });
};

export const changeUniversitySimpleBlog = (dispatch, id) => {
  dispatch({ type: LOAD_UNIVERSITY_SIMPLE_BLOG, eventId: id });
};

