import React, { useEffect } from 'react';
import { createContext, useReducer, useContext } from 'react';

import * as ACTION from './action';
import * as DateUtils from '../../utils/date-utils/date-utils';
import * as UnirefApi from '../../services/api/api';
import * as EventUtils from '../../utils/event-utils/event-utils';
import * as EventContextUtils from './events-context-utils';
import { STRUCTURES } from '../../services/api/api';

const EventsContext = createContext();

const eventsReducer = (state, action) => {
  switch (action.type) {
    case ACTION.CHANGE_DATE: {
      if (DateUtils.equalsDate(state.events.date, action.date, 2)) {
        return {
          ...state,
          events: {
            ...state.events,
            date: action.date,
            contents: action.isFullMonth
              ? state.events._fn.getAllEvents()
              : state.events._fn.getEvents(action.date),
            isFullMonth: action.isFullMonth,
            needReloadEvents: false,
          },
        };
      } else {
        return {
          ...state,
          events: {
            ...state.events,
            date: action.date,
            contents: null,
            _fn: null,
            isFullMonth: action.isFullMonth,
            needReloadEvents: true,
          },
        };
      }
    }
    case ACTION.LOAD_EVENTS: {
      return {
        ...state,
        events: {
          ...state.events,
          contents: action.contents,
          _fn: action._fn,
        },
      };
    }
    case ACTION.LOAD_EVENT: {
      return {
        ...state,
        selectedEvent: {
          eventId: action.eventId,
          structureId: [STRUCTURES.event, STRUCTURES.highlightedEvent],
          content: null,
          error: false,
        },
      };
    }
    case ACTION.LOAD_WORSHIP: {
      return {
        ...state,
        selectedEvent: {
          eventId: action.eventId,
          structureId: [STRUCTURES.worksip],
          content: null,
          error: false,
        },
      };
    }
    case ACTION.LOADED_EVENT: {
      return {
        ...state,
        selectedEvent: {
          ...state.selectedEvent,
          content: action.content,
          error: false,
        },
      };
    }
    case ACTION.LOADED_NEXT_EVENT: {
      return {
        ...state,
        nextEvent: {
          ...state.nextEvent,
          loaded: true,
          content: action.content,
        },
      };
    }
    case ACTION.LOADED_HIGHLIGHTED_EVENT: {
      return {
        ...state,
        highlightedEvent: {
          ...state.highlightedEvent,
          loaded: true,
          content: action.content,
        },
      };
    }
    case ACTION.LOADED_WORSHIPS: {
      return {
        ...state,
        worships: {
          ...state.worships,
          loaded: true,
          thisWeek: action.thisWeek,
          previousWeek: action.previousWeek,
        },
      };
    }
    case ACTION.LOADED_EVENT_EXCEPTION: {
      return {
        ...state,
        selectedEvent: {
          ...state.selectedEvent,
          error: true,
        },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const EventsProvider = ({ initialValues, children }) => {
  const [state, dispatch] = useReducer(eventsReducer, {
    events: EventContextUtils.calcEventsInitialValues(initialValues),
    nextEvent: EventContextUtils.calcNextEventInitialValues(initialValues),
    highlightedEvent: EventContextUtils.calcHighlightedEventInitialValues(initialValues),
    worships: EventContextUtils.calcWorshipInitialValues(initialValues),
    selectedEvent: EventContextUtils.calcSelectedEventInitialValues(initialValues),
  });
  const value = { state, dispatch };

  /**
   * Load all events in selected month.
   */
  useEffect(() => {
    if (state.events.date !== null && state.events.needReloadEvents) {
      const fetchEvents = async () => {
        const eventsList = await UnirefApi.getEvents(state.events.date);

        dispatch({
          type: ACTION.LOAD_EVENTS,
          contents: eventsList,
          _fn: EventContextUtils.buildFunctionsForEvents(
            eventsList,
            state.events.date
          ),
        });
      };
      fetchEvents();
    }
  }, [state.events.date, state.events.needReloadEvents]);

  /**
   * Load selected event.
   */
  useEffect(() => {
    if (
      state.selectedEvent.eventId &&
      state.selectedEvent.eventId !== state.selectedEvent?.content?.id
    ) {
      const fetchEvent = async () => {
        try {
          let selectedEvent = state.events._fn
            ?.getAllEvents()
            .find((event) => +event.id === +state.selectedEvent.eventId);
          if (!selectedEvent) {
            selectedEvent = await UnirefApi.getEvent(
              state.selectedEvent.structureId,
              state.selectedEvent.eventId
            );
          }
          dispatch({ type: ACTION.LOADED_EVENT, content: selectedEvent });
        } catch (ex) {
          dispatch({ type: ACTION.LOADED_EVENT_EXCEPTION });
        }
      };
      fetchEvent();
    }
  }, [
    state.events._fn,
    state.selectedEvent.structureId,
    state.selectedEvent.eventId,
  ]);

  /**
   * Load next event and save in context.
   *
   * @param {Object} nextEvent object from session (only first time and only arrive from server side)
   */
  const loadNextEvent = async (nextEvent) => {
    const prospectiveEvents = nextEvent?.loaded
      ? nextEvent?.content
        ? [nextEvent.content]
        : []
      : await UnirefApi.getProspectiveEvents();
    /* 10 minutes */
    const waitinTime = 1000 * 60 * 10;

    if (prospectiveEvents?.length > 0) {
      const prospectiveEvent = prospectiveEvents[0];
      let diffTime = EventUtils.getStartDate(prospectiveEvent) - new Date();
      if (diffTime > waitinTime) {
        diffTime = waitinTime;
      }
      setTimeout(() => {
        loadNextEvent();
      }, diffTime + 1000 * 1);
      dispatch({
        type: ACTION.LOADED_NEXT_EVENT,
        content: prospectiveEvent,
      });
    } else {
      setTimeout(() => {
        loadNextEvent();
      }, waitinTime);
      dispatch({
        type: ACTION.LOADED_NEXT_EVENT,
        content: null,
      });
    }
  };

  /**
   * Load highlighted event and save in context.
   *
   * @param {Object} highlightedEvent object from session (only first time and only arrive from server side)
   */
  const loadHighlightedEvent = async (highlightedEvent) => {
    const highlightedEvents = highlightedEvent?.loaded
      ? highlightedEvent?.content
        ? [highlightedEvent.content]
        : []
      : await UnirefApi.getHighlightedEvent();

      if (highlightedEvents?.length > 0) {
        const highlightedEvent = highlightedEvents[0];
        dispatch({
          type: ACTION.LOADED_HIGHLIGHTED_EVENT,
          content: highlightedEvent,
        });
      } else {
        dispatch({
          type: ACTION.LOADED_HIGHLIGHTED_EVENT,
          content: null,
        });
      }
  };

  /**
   * Load next event (for event countdown).
   */
  useEffect(() => {
    const fetchContext = async () => {
      loadNextEvent(state.nextEvent);
      loadHighlightedEvent(state.highlightedEvent);
    };
    fetchContext();
  }, []);

  /**
   * Load worships.
   */
  useEffect(() => {
    if (!state.worships.loaded) {
      const fetchContext = async () => {
        const worshipsInCurrentAndPreviousWeek =
          await UnirefApi.getWorshipsInCurrentAndPreviousWeek();
        dispatch({
          type: ACTION.LOADED_WORSHIPS,
          thisWeek: worshipsInCurrentAndPreviousWeek.thisWeek,
          previousWeek: worshipsInCurrentAndPreviousWeek.previousWeek,
        });
      };
      fetchContext();
    }
  }, []);

  return (
    <EventsContext.Provider value={value}>{children}</EventsContext.Provider>
  );
};

export const useEvents = () => {
  const context = useContext(EventsContext);
  if (context === undefined) {
    throw new Error('useEvents must be used within a EventsProvider');
  }
  return context;
};
