/**
 * Action to load the selected blogs page.
 */
export const CHANGE_PAGE = 'changePage';
export const CHANGE_STRUCTURE = 'changeStructure';
export const LOADED_PAGE = 'loadedPage';

/**
 * Success loading movie or simple blog content.
 */
export const LOADED_BLOG = 'loadedBlog';

/**
 * Failed loading movie or simple blog content.
 */
export const LOADED_BLOG_EXCEPTION = 'loadedBlogException';

/**
 * Action to load the selected simple blog content.
 */
export const LOAD_SIMPLE_BLOG = 'loadDimpleBlog';

/**
 * Action to load the selected movie blog content.
 */
export const LOAD_MOVIE_BLOG = 'loadMovieBlog';

/**
 * Change page (reload the blogs in reducer)
 * @param {Object} dispatch object
 * @param {Number} page selected page
 */
export const changePage = (dispatch, page) => {
  dispatch({ type: CHANGE_PAGE, page: page });
};

/**
 * Change page (reload the blogs in reducer)
 * @param {Object} dispatch object
 * @param {structure} structure selected structure (or null)
 */
export const changeStructure = (dispatch, structure) => {
  dispatch({ type: CHANGE_STRUCTURE, structure: structure });
};

/**
 * Change selected simple blog content (reload the selected content in reducer)
 * @param {Object} dispatch object
 * @param {Number} id of the selected content
 */
export const changeSimpleBlog = (dispatch, id) => {
  dispatch({ type: LOAD_SIMPLE_BLOG, eventId: id });
};

/**
 * Change selected movie blog content (reload the selected content in reducer)
 * @param {Object} dispatch object
 * @param {Number} id of the selected content
 */
export const changeMovieBlog = (dispatch, id) => {
  dispatch({ type: LOAD_MOVIE_BLOG, eventId: id });
};
