import React from 'react';
import PropTypes from 'prop-types';

export const ContentBox = ({ title, center, children }) => (
  <div
    className={`component-content-box pt pb-2x pb-lg-4x pr-2x pr-lg-4x pl-2x pl-lg-4x pb-2x${
      center ? ' text-center' : ''
    }`}
  >
    {title ? (
      <h2>{title}</h2>
    ) : (
      <div className="content-box-placeholder no-select" />
    )}
    {children}
  </div>
);

ContentBox.defaultProps = {
  title: null,
  center: false,
};

ContentBox.prototype = {
  /**
   * Title of box
   */
  title: PropTypes.string,
  /**
   * Align center if true
   */
  center: PropTypes.bool.isRequired,
  /**
   * Content in box
   */
  children: PropTypes.element.isRequired,
};
