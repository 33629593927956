export const AVAILABILITY = {
  address: '6722 Szeged, Szentháromság utca 26.',
  email: 'uniref.szeged@gmail.com',
};

export const SOCIAL_PAGES = {
  facebook: 'https://www.facebook.com/reformatus.szeged',
  instagram: 'https://www.instagram.com/uniref.szeged/',
  anchor: 'https://anchor.fm/uniref-szeged',
  spotify: 'https://open.spotify.com/show/49dARURM8WyP2EHVo9OnJW',
  youTube:
    'https://www.youtube.com/channel/UC-bF-MwJnNnl5wQXWWlJtSw?fbclid=IwAR2d0fIWpdwBzWoBBVI3qyb9C6TyXTJZKqFkmaJ-x45wRWsJkD1HEGsNBUw',
  deezer: 'https://www.deezer.com/hu/show/3084242',
  website: 'https://uniref-szeged.hu/',
};

export const IMPRESS = {
  operator: [
    {
      name: 'Keresztpont Közösség',
      photo: '/images/impress/crosspoint.png',
      email: 'keresztpont.szeged@gmail.com',
      facebook: 'https://www.facebook.com/keresztpont',
      youtube: 'https://www.youtube.com/channel/UCRtgyqJRwCJp4xXkI8qlX2w',
      instagram: 'https://www.instagram.com/keresztpont/',
    },
  ],
  universityPastor: [
    {
      name: 'Damásdi Péter',
      photo: '/images/impress/pdamasdi.jpg',
      email: 'damasdi.peter@gmail.com',
      facebook: 'https://www.facebook.com/peter.damasdi',
    },
  ],
  designer: [{ name: 'Balogh Gergő', photo: '/images/impress/gbalogh.jpg' }],
  developer: [
    {
      name: 'Csúri Krisztián',
      photo: '/images/impress/kcsuri.png',
      email: 'csuri.krisztian89@gmail.com',
      facebook: 'https://www.facebook.com/krisztian.csuri.35',
      website: 'https://kcsuri.hu/',
    },
  ],
};
