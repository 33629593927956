import React from 'react';
import PropTypes from 'prop-types';

export const Row = ({ className, children }) => (
  <div className={`row${className ? ' ' + className : ''}`}>{children}</div>
);

Row.defaultProps = {
  className: null,
};

Row.prototype = {
  /**
   * Extra class in row
   */
  className: PropTypes.string,
  /**
   * Content in row
   */
  children: PropTypes.element.isRequired,
};
