import * as DateUtils from '../../utils/date-utils/date-utils';

const fetch = require('node-fetch');

const BIBLE_RECOMMENDATION_API_DOMAIN =
  typeof window === 'object' && window?.initialValues?.REACT_APP_API_DOMAIN_BIBLE_RECOMMENDATION
    ? window.initialValues.REACT_APP_API_DOMAIN_BIBLE_RECOMMENDATION
    : process.env.REACT_APP_API_DOMAIN_BIBLE_RECOMMENDATION;
const BIBLE_API_DOMAIN =
  typeof window === 'object' && window?.initialValues?.REACT_APP_API_DOMAIN_BIBLE
    ? window.initialValues.REACT_APP_API_DOMAIN_BIBLE
    : process.env.REACT_APP_API_DOMAIN_BIBLE;
const SONG_API_DOMAIN =
  typeof window === 'object' && window?.initialValues?.REACT_APP_API_DOMAIN_SONG
    ? window.initialValues.REACT_APP_API_DOMAIN_SONG
    : process.env.REACT_APP_API_DOMAIN_SONG;
const API_DOMAIN =
  typeof window === 'object' && window?.initialValues?.REACT_APP_API_DOMAIN
    ? window.initialValues.REACT_APP_API_DOMAIN
    : process.env.REACT_APP_API_DOMAIN_SERVER;
const API_DOMAIN_IN_CLIENT =
  typeof window === 'object' && window?.initialValues?.REACT_APP_API_DOMAIN
    ? window.initialValues.REACT_APP_API_DOMAIN
    : process.env.REACT_APP_API_DOMAIN;

export const TENANT = 'uniref';

const LANGUAGE = 'hu-HU';

export const STRUCTURES = {
  worksip: 'worshipS',
  event: 'eventS',
  highlightedEvent: 'highlightedEventS',
  blogSimple: 'simpleBlogS',
  blogMovie: 'movieBlogS',
  semester: 'semesterS',
  glossaryblog: 'glossaryblogS',
  restaurantsblog: 'restaurantsblogS',
  universityblog: 'universityblogS',
  universitySimpleBlog: 'universitySimpleBlogS',
};

export const STRUCTURES_URLPATH = {
  blogSimple: 'reszemanagytortenetben',
  blogMovie: 'hitemakameralencsenat',
};

export const getUrlPathToStructure = (structure) => {
  switch (structure) {
    case STRUCTURES.blogMovie:
      return STRUCTURES_URLPATH.blogMovie;
    case STRUCTURES.blogSimple:
      return STRUCTURES_URLPATH.blogSimple;
    default:
      return null
  }
}

export const getStructureToUrlPath = (urlPath) => {
  switch (urlPath) {
    case STRUCTURES_URLPATH.blogMovie:
      return STRUCTURES.blogMovie;
    case STRUCTURES_URLPATH.blogSimple:
      return STRUCTURES.blogSimple;
    default:
      return null
  }
}

export const BLOG_LIMIT = 6;

export const buildImageLink = (structureId, contentId, size = 'thumbnail') => {
  if (structureId && contentId) {
    return `${API_DOMAIN_IN_CLIENT}/media/media/${structureId}/${contentId}/${size}`;
  } else {
    return '/images/uniref-share.png';
  }
};

export const getBibleRecommendation = async () => {
  const query = `${BIBLE_RECOMMENDATION_API_DOMAIN}/poemfromgroup?groupId=1`;
  try {
    const response = await fetch(query)
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            throw new Error('Error in api call');
        }
      })
      .then((serverResponse) => {
        return serverResponse;
      })
      .catch((error) => {
        throw error;
      });
    return response;
  } catch (error) {
    throw error;
  }
}

export const getBibleSection = async (location) => {
  const query =
    `${BIBLE_API_DOMAIN}poems` +
    `?fromBook=${location.fromBook}` +
    `&fromChapter=${location.fromChapter}` +
    `&fromPoem=${location.fromPoem}` +
    `&toBook=${location.toBook}` +
    `&toChapter=${location.toChapter}` +
    `&toPoem=${location.toPoem}`;
  try {
    const response = await fetch(query)
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            throw new Error('Error in api call');
        }
      })
      .then((serverResponse) => {
        return serverResponse;
      })
      .catch((error) => {
        throw error;
      });
    return response;
  } catch (error) {
    throw error;
  }
}

export const getSemesters = async () => {
  const query =
    `${API_DOMAIN}/content/list` +
    `?limit=1000` +
    `&locale=hu-HU` +
    `&meta=false` +
    `&offset=0` +
    `&structureId=${STRUCTURES.semester}`;
  return fetch(query)
    .then((response) => response.json())
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch((exception) => {
      return Promise.reject(exception);
    });
}

export const getSongs = async (songs) => {
  const promises = songs.map(song => fetch(`${SONG_API_DOMAIN}/song?id=${song.song}`).then(y => y.text()));
  return Promise.all(promises).then(results => {
    return results.map((song, _) => {
      return JSON.parse(song);
    });
  });
}

export const getWorshipsInCurrentAndPreviousWeek = async () => {
  const loadContent = (date) => {
    const week = DateUtils.getWeekInYear(date);
    const query =
      `${API_DOMAIN}/event/week` +
      `?locale=${LANGUAGE}` +
      `&meta=false` +
      `&structure=${STRUCTURES.worksip}` +
      `&week=${week}` +
      `&year=${date.getFullYear()}`;
    return fetch(query)
      .then((response) => response.json())
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((exception) => {
        return Promise.reject(exception);
      });
  };

  const currentWeek = new Date();
  let previousWeek = new Date();
  previousWeek.setDate(previousWeek.getDate() - 7);
  return Promise.all([
    loadContent(currentWeek),
    loadContent(previousWeek),
  ]).then((values) => {
    return {
      thisWeek: values[0].length > 0 ? values[0][0] : null,
      previousWeek: values[1].length > 0 ? values[1][0] : null,
    };
  });
};

export const getProspectiveEvents = async () => {
  const query =
    `${API_DOMAIN}/event/prospective` +
    `?locale=${LANGUAGE}` +
    `&meta=false` +
    `&structure=${STRUCTURES.worksip}` +
    `&structure=${STRUCTURES.event}`;
  try {
    const response = await fetch(query)
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            throw new Error('Error in api call');
        }
      })
      .then((serverResponse) => {
        return serverResponse;
      })
      .catch((error) => {
        throw error;
      });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getHighlightedEvent = async () => {
  const query =
    `${API_DOMAIN}/event/prospective` +
    `?locale=${LANGUAGE}` +
    `&meta=false` +
    `&structure=${STRUCTURES.highlightedEvent}`;
  try {
    const response = await fetch(query)
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            throw new Error('Error in api call');
        }
      })
      .then((serverResponse) => {
        return serverResponse;
      })
      .catch((error) => {
        throw error;
      });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getBlogContent = async (structureId, id) => {
  const query =
    `${API_DOMAIN}/content/` +
    `?contentId=${id}` +
    `&locale=${LANGUAGE}` +
    `&meta=false` +
    `&structureId=${structureId}`;
  try {
    const response = await fetch(query)
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            throw new Error('Error in api call');
        }
      })
      .then((serverResponse) => {
        return serverResponse;
      })
      .catch((error) => {
        throw error;
      });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUniversitiesPage = async (page, limit) => {
  const offset = (page - 1) * limit;
  let query =
    `${API_DOMAIN}/content/list` +
    `?limit=${limit}` +
    `&locale=${LANGUAGE}` +
    `&meta=false` +
    `&offset=${offset}` +
    `&structureId=${STRUCTURES.glossaryblog}` +
    `&structureId=${STRUCTURES.universityblog}`+
    `&structureId=${STRUCTURES.restaurantsblog}`+
    `&structureId=${STRUCTURES.universitySimpleBlog}`;
  try {
    const response = await fetch(query)
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            throw new Error('Error in api call');
        }
      })
      .then((serverResponse) => {
        return serverResponse;
      })
      .catch((error) => {
        throw error;
      });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getBlogsPage = async (page, limit, structure) => {
  const offset = (page - 1) * limit;
  let query =
    `${API_DOMAIN}/content/list` +
    `?limit=${limit}` +
    `&locale=${LANGUAGE}` +
    `&meta=false` +
    `&offset=${offset}`;
  if (structure === null) {
    query +=
      `&structureId=${STRUCTURES.blogMovie}` +
      `&structureId=${STRUCTURES.blogSimple}`;
  } else {
    query +=
      `&structureId=${structure}`;
  }
  try {
    const response = await fetch(query)
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            throw new Error('Error in api call');
        }
      })
      .then((serverResponse) => {
        return serverResponse;
      })
      .catch((error) => {
        throw error;
      });
    return response;
  } catch (error) {
    throw error;
  }
};


export const getWorships = async (start, end) => {
  const query =
    `${API_DOMAIN}/event/period` +
    `?locale=${LANGUAGE}` +
    `&meta=false` +
    `&structure=${STRUCTURES.worksip}` +
    `&startYear=${start.getFullYear()}` +
    `&startMonth=${start.getMonth() + 1}` +
    `&startDay=${start.getDate()}` +
    `&endYear=${end.getFullYear()}` +
    `&endMonth=${end.getMonth() + 1}` +
    `&endDay=${end.getDate()}`;
  try {
    const response = await fetch(query)
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            throw new Error('Error in api call');
        }
      })
      .then((serverResponse) => {
        return serverResponse;
      })
      .catch((error) => {
        throw error;
      });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getEvents = async (date) => {
  const query =
    `${API_DOMAIN}/event/month` +
    `?locale=${LANGUAGE}` +
    `&meta=false` +
    `&month=${date.getMonth() + 1}` +
    `&structure=${STRUCTURES.worksip}` +
    `&structure=${STRUCTURES.event}` +
    `&structure=${STRUCTURES.highlightedEvent}` +
    `&year=${date.getFullYear()}`;
  try {
    const response = await fetch(query)
      .then((response) => {
        switch (response.status) {
          case 200:
            return response.json();
          default:
            throw new Error('Error in api call');
        }
      })
      .then((serverResponse) => {
        return serverResponse;
      })
      .catch((error) => {
        throw error;
      });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getEvent = async (structureIds, id) => {
  const queries = structureIds.map(structureId => fetch(`${API_DOMAIN}/event/` +
    `?contentId=${id}` +
    `&locale=${LANGUAGE}` +
    `&meta=false` +
    `&structureId=${structureId}`).then(y => y.status === 200 ? y.json() : null));
  return Promise.all(queries).then(results => {
    return results.find(content => content != null);
  });
};
