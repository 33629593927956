import React from 'react';
import PropTypes from 'prop-types';

export const Separator = ({ small, color }) => (
  <hr
    className={`component-separator separator-${color} ${
      small ? 'separator-small' : ''
    }`}
  />
);

Separator.defaultProps = {
  small: false,
  color: 'green',
};

Separator.prototype = {
  /**
   * Size of separator (small or not small).
   */
  small: PropTypes.bool.isRequired,
  /**
   * Color of separator (green or red).
   */
  color: PropTypes.oneOf(['green', 'red']).isRequired,
};
