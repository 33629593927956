import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagram,
  faSpotify,
  faDeezer,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faPodcast } from '@fortawesome/free-solid-svg-icons';

import { SOCIAL_PAGES } from '../../../datas/infos/infos';

export const SocialPages = ({ horizontal, animated, showWebsite }) => {
  const intl = useIntl();

  const Block = ({ id, link, text, icon }) => {
    if (horizontal) {
      return (
        <a
          className={`${animated ? 'animated ' : null}${id}`}
          href={link}
          rel="noreferrer"
          target="_blank"
          title={intl.formatMessage({
            id: text,
          })}
        >
          <FontAwesomeIcon icon={icon} size="2x" fixedWidth />
        </a>
      );
    } else {
      return (
        <div className="d-block">
          <a
            className={`${animated ? 'animated ' : null}${id}`}
            href={link}
            rel="noreferrer"
            target="_blank"
            title={intl.formatMessage({
              id: text,
            })}
          >
            <FontAwesomeIcon icon={icon} size="2x" fixedWidth />
            <FormattedMessage id={text} />
          </a>
        </div>
      );
    }
  }

  return (
    <div className="component-social-pages">
      <Block id='facebook'
        link={SOCIAL_PAGES.facebook}
        text='community.facebook'
        icon={faFacebookSquare} />
      <Block id='instagram'
        link={SOCIAL_PAGES.instagram}
        text='community.instagram'
        icon={faInstagram} />
      <Block id='anchor'
        link={SOCIAL_PAGES.anchor}
        text='community.anchor'
        icon={faPodcast} />
      <Block id='spotify'
        link={SOCIAL_PAGES.spotify}
        text='community.spotify'
        icon={faSpotify} />
      <Block id='deezer'
        link={SOCIAL_PAGES.deezer}
        text='community.deezer'
        icon={faDeezer} />
      <Block id='youTube'
        link={SOCIAL_PAGES.youTube}
        text='community.youTube'
        icon={faYoutube} />
      {showWebsite ? (
        <Block id='website'
          link={SOCIAL_PAGES.website}
          text='community.website'
          icon={faGlobe} />
      ) : null}
    </div>
  );
};

SocialPages.defaultProps = {
  horizontal: true,
  animated: true,
  showWebsite: false
};

SocialPages.prototype = {
  /**
   * horizontal or vertical align
   */
  horizontal: PropTypes.bool.isRequired,
  /**
   * Animated or not the icons
   */
  animated: PropTypes.bool.isRequired,
  /**
   * Show website
   */
  showWebsite: PropTypes.bool.isRequired,
};
