import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { AVAILABILITY } from '../../datas/infos/infos';
import { PAGES } from '../../datas/pages/pages';
import * as Layout from '../layout';
import { Separator } from '../common/separator/separator';
import { SocialPages } from '../common/social-pages/social-pages';

export const Footer = () => {
  return (
    <footer className="component-footer">
      <Layout.Container>
        <Layout.Row>
          <Layout.Col className="text-center" col="12">
            <Separator />
            <div className="h2">
              <FormattedMessage id="footer.title" />
            </div>
            <div className="mb-2x avaliablity">
              <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth />
              <span className="ml-half">{AVAILABILITY.address}</span>
            </div>
            <div className="mb-2x avaliablity">
              <a className="ml-half" href={`mailto:${AVAILABILITY.email}`}>
                <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                <span className="pl-half">{AVAILABILITY.email}</span>
              </a>
            </div>
            <div className="mb-2x">
              <SocialPages />
            </div>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
      <Layout.Container fluid>
        <Layout.Row>
          <Layout.Col col="12">
            <Separator small />
          </Layout.Col>
        </Layout.Row>
        <Layout.Container className="pt pb-2x">
          <Layout.Row className="font-base">
            <Layout.Col col="12" md="10">
              <FormattedMessage id="footer.copyright" />
            </Layout.Col>
            <Layout.Col className="text-md-right no-select" col="12" md="2">
              <ul className="footer-nav">
                {Object.keys(PAGES)
                  .filter((page) => PAGES[page].footer)
                  .map((page) => (
                    <li key={page}>
                      <Link to={PAGES[page].url}>
                        <FormattedMessage id={`pages.${page}`} />
                      </Link>
                    </li>
                  ))}
              </ul>
            </Layout.Col>
          </Layout.Row>
        </Layout.Container>
      </Layout.Container>
    </footer>
  );
};
