import * as DateUtils from '../../utils/date-utils/date-utils';

/**
 * Calc events contents from initialValues (from server side) param or window.initialValues object.
 *
 * @param {Object} initialValues from server side
 * @returns {Object} events object to context
 */
export const calcEventsInitialValues = (initialValues) => {
  let events = {
    date: null,
    contents: null,
    _fn: null,
    needReloadEvents: false,
  };
  if (initialValues?.events) {
    events = {
      date: new Date(initialValues.events.date),
      contents: initialValues.events.contents,
      _fn: buildFunctionsForEvents(
        initialValues.events.contents,
        new Date(initialValues.events.date)
      ),
      needReloadEvents: false,
    };
  } else if (typeof window === 'object' && window?.initialValues?.events) {
    events = {
      date: new Date(window.initialValues.events.date),
      contents: window.initialValues.events.contents,
      _fn: buildFunctionsForEvents(
        window.initialValues.events.contents,
        new Date(window.initialValues.events.date)
      ),
      needReloadEvents: false,
    };
  }

  return events;
};

/**
 * Calc next event content from initialValues (from server side) param or window.initialValues object.
 *
 * @param {Object} initialValues from server side
 * @returns {Object} event object to context
 */
export const calcNextEventInitialValues = (initialValues) => {
  let event = {
    loaded: false,
    content: null,
  };
  if (initialValues?.nextEvent) {
    event = {
      loaded: initialValues.nextEvent.loaded,
      content:
        initialValues.nextEvent.contents?.length > 0
          ? initialValues.nextEvent.contents[0]
          : null,
    };
  } else if (typeof window === 'object' && window?.initialValues?.nextEvent) {
    event = {
      loaded: window.initialValues.nextEvent.loaded,
      content:
        window.initialValues.nextEvent.contents?.length > 0
          ? window.initialValues.nextEvent.contents[0]
          : null,
    };
  }
  return event;
};

/**
 * Calc highlighted event content from initialValues (from server side) param or window.initialValues object.
 *
 * @param {Object} initialValues from server side
 * @returns {Object} event object to context
 */
export const calcHighlightedEventInitialValues = (initialValues) => {
  let event = {
    loaded: false,
    content: null,
  };
  if (initialValues?.highlightedEvent) {
    event = {
      loaded: initialValues.highlightedEvent.loaded,
      content:
        initialValues.highlightedEvent.contents?.length > 0
          ? initialValues.highlightedEvent.contents[0]
          : null,
    };
  } else if (typeof window === 'object' && window?.initialValues?.highlightedEvent) {
    event = {
      loaded: window.initialValues.highlightedEvent.loaded,
      content:
        window.initialValues.highlightedEvent.contents?.length > 0
          ? window.initialValues.highlightedEvent.contents[0]
          : null,
    };
  }
  return event;
};

/**
 * Calc this and previous week worship content from initialValues (from server side) param or window.initialValues object.
 *
 * @param {Object} initialValues from server side
 * @returns {Object} worship object to context
 */
export const calcWorshipInitialValues = (initialValues) => {
  let worships = {
    loaded: false,
    thisWeek: null,
    previousWeek: null,
  };
  if (initialValues?.worships) {
    worships = {
      loaded: true,
      thisWeek: initialValues.worships.thisWeek,
      previousWeek: initialValues.worships.previousWeek,
    };
  } else if (typeof window === 'object' && window?.initialValues?.worships) {
    worships = {
      loaded: true,
      thisWeek: window.initialValues.worships.thisWeek,
      previousWeek: window.initialValues.worships.previousWeek,
    };
  }

  return worships;
};

/**
 * Calc selected event content from initialValues (from server side) param or window.initialValues object.
 *
 * @param {Object} initialValues from server side
 * @returns {Object} selected event object to context
 */
export const calcSelectedEventInitialValues = (initialValues) => {
  let event = {
    eventId: null,
    content: null,
    error: false,
  };
  if (initialValues?.event) {
    event = {
      eventId: initialValues.event.eventId,
      content: initialValues.event.content,
      error: false,
    };
  } else if (typeof window === 'object' && window?.initialValues?.event) {
    event = {
      eventId: window.initialValues.event.eventId,
      content: window.initialValues.event.content,
      error: false,
    };
  }

  return event;
};

/**
 * Build functions for events (getAllEvents(), getEvents(date), hasEvent(day)).
 *
 * @param {Object} eventsList events list
 * @returns {Object} events functions to context
 */
export const buildFunctionsForEvents = (eventsList, date) => {
  let dayWithEvent = new Map();
  eventsList.forEach((event) => {
    const startDay = getFirstDayOfTheEventInMonth(
      new Date(event.contentExtension.properties.map.startDate),
      date
    );
    const endDay = getLastDayOfTheEventInMonth(
      new Date(event.contentExtension.properties.map.endDate),
      date
    );
    for (let i = startDay; i <= endDay; i++) {
      if (dayWithEvent.has(i)) {
        dayWithEvent.get(i).push(event);
      } else {
        dayWithEvent.set(i, [event]);
      }
    }
  });

  return {
    getAllEvents: () => {
      return eventsList;
    },
    getEvents: (date) => {
      const day = date.getDate();
      return dayWithEvent.has(day) ? dayWithEvent.get(day) : [];
    },
    hasEvent: (day) => {
      return dayWithEvent.has(day);
    },
  };
};

/**
 * First day of the event in the current month.
 *
 * @param {Date} startDateOfEvent start date of selected event
 * @param {Date} currentDate current date
 * @returns {number} first day of the event on the current month.
 */
export const getFirstDayOfTheEventInMonth = (startDateOfEvent, currentDate) => {
  return startDateOfEvent.getFullYear() < currentDate.getFullYear() ||
    startDateOfEvent.getMonth() < currentDate.getMonth()
    ? 1
    : startDateOfEvent.getDate();
};

/**
 * Last day of the event in the current month.
 *
 * @param {Date} startDateOfEvent start date of selected event
 * @param {Date} currentDate current date
 * @returns {number} last day of the event on the current month.
 */
export const getLastDayOfTheEventInMonth = (endDateOfEvent, currentDate) => {
  return endDateOfEvent.getFullYear() > currentDate.getFullYear() ||
    endDateOfEvent.getMonth() > currentDate.getMonth()
    ? DateUtils.getLastDateOfMonth(currentDate)
    : endDateOfEvent.getDate();
};
