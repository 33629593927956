import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const Loading = () => {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setVisible(true);
    }, 800);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return (
    <>
      {isVisible ? (
        <div className="component-loading text-center no-select">
          <FontAwesomeIcon icon={faSpinner} size="2x" fixedWidth pulse />
        </div>
      ) : null}
    </>
  );
};
