/**
 * Action to load the events in the selected month.
 */
export const CHANGE_DATE = 'changeDate';
export const LOAD_EVENTS = 'LoadEventsInMonth';

/**
 * Action to load the selected event.
 */
export const LOAD_EVENT = 'loadEvent';

/**
 * Action to load the selected worship.
 */
export const LOAD_WORSHIP = 'loadWorship';

/**
 * Success loading event or worship.
 */
export const LOADED_EVENT = 'loadedEvent';

/**
 * Exception in load event or worship.
 */
export const LOADED_EVENT_EXCEPTION = 'loadedEventException';

/**
 * Action to load the next event (following event to event downcount).
 */
export const LOADED_NEXT_EVENT = 'loadedNextEvent';

/**
 * Action to load the Highlighted event (e.x. summer camping).
 */
export const LOADED_HIGHLIGHTED_EVENT = 'loadedHighlightedEvent';

/**
 * Action to load the previous week and current week worships.
 */
export const LOADED_WORSHIPS = 'loadedWorships';

/**
 * Change date (reload the events in reducer)
 * @param {Object} dispatch object
 * @param {Date} date selected date
 * @param {Boolean} isFullMonth if true load the full month in another case load the selected day
 */
export const changeDate = (dispatch, date, isFullMonth) => {
  dispatch({ type: CHANGE_DATE, date: date, isFullMonth: isFullMonth });
};

/**
 * Change selected event (reload the selected event in reducer)
 * @param {Object} dispatch object
 * @param {Number} id of the selected event
 */
export const changeEvent = (dispatch, id) => {
  dispatch({ type: LOAD_EVENT, eventId: id });
};

/**
 * Change selected worship (reload the selected event in reducer)
 * @param {Object} dispatch object
 * @param {Number} id of the selected worship
 */
export const changeWorship = (dispatch, id) => {
  dispatch({ type: LOAD_WORSHIP, eventId: id });
};
