import React from 'react';
import PropTypes from 'prop-types';

import { EventsProvider } from './events/events';
import { BlogsProvider } from './blogs/blogs';
import { UniversitiesProvider } from './university/university';

export const Providers = ({ initialValues, children }) => (
  <EventsProvider initialValues={initialValues}>
    <BlogsProvider initialValues={initialValues}>
      <UniversitiesProvider initialValues={initialValues}>
        {children}
      </UniversitiesProvider>
    </BlogsProvider>
  </EventsProvider>
)

Providers.prototype = {
  /**
   * Title of modal
   */
   initialValues: PropTypes.object,
  /**
   * body of modal
   */
  children: PropTypes.string.isRequired
};
