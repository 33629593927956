import { STRUCTURES } from '../../services/api/api';

export const getEventTitle = (content, intl) => {
  return content.structureId === STRUCTURES.worksip
    ? intl.formatMessage({
        id: `event.type.worship`,
      })
    : content.content.title;
};

export const getEventSubtitle = (content) => {
  return content.structureId === STRUCTURES.worksip
    ? content.content.title
    : content.content.subtitle;
};

export const getFullTitle = (content, intl) => {
  const title = getEventTitle(content, intl);
  const subTitle = getEventSubtitle(content);
  if (subTitle) {
    return `${title}: ${subTitle}`;
  } else {
    return title;
  }
};

export const getEventLead = (content) => {
  return content.content.lead;
};

export const getEventDescription = (content) => {
  return content.content.content;
};

export const getEventSongs = (content) => {
  if (content.content.songRow?.length === 1 && content.content.songRow[0].song === ''){
    return [];
  } else {
    return content.content.songRow;
  }  
};

export const getFriendlyUrl = (content) => {
  return content.friendlyUrl;
};

export const getStartDate = (content) => {
  return new Date(content.contentExtension.properties.map.startDate);
};

export const getEndDate = (content) => {
  return new Date(content.contentExtension.properties.map.endDate);
};

export const isFullDay = (content) => {
  return content.contentExtension.properties.map.fullDay;
};

export const getEventImage = (content) => {
  return {
    structureId: content.content.imageProp[0].imageFile.structureId,
    contentId: content.content.imageProp[0].imageFile.contentId,
  };
};

export const getEventImageProp = (content) => {
  return content.content.imageProp[0];
};

export const getLocationName = (content, intl) => {
  if (
    typeof content.content?.locationRow === 'object' &&
    content.content.locationRow.length > 0
  ) {
    const location = content.content.locationRow[0];
    if (location.locationFix) {
      return intl.formatMessage({
        id: `location.text.${location.locationFix}`,
      });
    } else if (location.locationOther) {
      return location.locationOther;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getLocationAddress = (content, intl) => {
  if (
    typeof content.content?.locationRow === 'object' &&
    content.content.locationRow.length > 0
  ) {
    const location = content.content.locationRow[0];
    if (location.locationFix) {
      return intl.formatMessage({
        id: `location.address.${location.locationFix}`,
      });
    } else {
      return location.locationAddress;
    }
  } else {
    return null;
  }
};

export const getLocationNote = (content) => {
  if (
    typeof content.content?.locationRow === 'object' &&
    content.content.locationRow.length > 0
  ) {
    const location = content.content.locationRow[0];
    if (location.locationNote) {
      return location.locationNote;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const isOnlineEvent = (content) => {
  if (
    typeof content.content?.locationRow === 'object' &&
    content.content.locationRow.length > 0
  ) {
    const location = content.content.locationRow[0];
    return location.zoomId;
  } else {
    return false;
  }
};

export const getEventZoomId = (content) => {
  if (
    typeof content.content?.locationRow === 'object' &&
    content.content.locationRow.length > 0
  ) {
    const location = content.content.locationRow[0];
    return location.zoomId;
  } else {
    return null;
  }
};

export const getLinks = (content) => {
  return content.content.linkProp;
}

export const getEventFacebookId = (content) => {
  return content.content.facebookEvent;
};

export const getEventFacebookPage = (content) => {
  return content.content.facebookPageName;
};

export const getEventWebsite = (content) => {
  return content.content.website;
};
