export const PAGES = {
  contact: {
    url: '/kapcsolat',
    navbar: false,
    footer: false,
    submenus: [],
  },
  bible: {
    url: '/ige',
    navbar: false,
    footer: false,
    submenus: [],
  },
  home: {
    url: '/',
    navbar: false,
    footer: false,
    submenus: [],
  },
  aboutus: {
    url: '/kikvagyunk',
    navbar: true,
    footer: false,
    submenus: [],
  },
  events: {
    url: '/esemenyek',
    navbar: true,
    footer: false,
    submenus: ['/esemeny', '/istentisztelet'],
  },
  event: {
    url: '/esemeny',
    pathParam: 'idAndTitle',
    onlyWithPathParam: true,
    navbar: false,
    footer: false,
    submenus: [],
  },
  worship: {
    url: '/istentisztelet',
    pathParam: 'idAndTitle',
    onlyWithPathParam: false,
    navbar: false,
    footer: false,
    submenus: [],
  },
  blog: {
    url: '/blog',
    pathParam: 'type',
    onlyWithPathParam: false,
    navbar: true,
    footer: false,
    submenus: [],
  },
  simpleBlog: {
    url: '/bizonysagtetel',
    pathParam: 'idAndTitle',
    onlyWithPathParam: true,
    navbar: false,
    footer: false,
    submenus: [],
  },
  movieBlog: {
    url: '/film',
    pathParam: 'idAndTitle',
    onlyWithPathParam: true,
    navbar: false,
    footer: false,
    submenus: [],
  },
  universities: {
    url: '/egyetem',
    pathParam: 'type',
    onlyWithPathParam: false,
    navbar: true,
    footer: false,
    submenus: [],
  },
  glossaryBlog: {
    url: '/fogalomtar',
    pathParam: 'idAndTitle',
    onlyWithPathParam: true,
    navbar: false,
    footer: false,
    submenus: [],
  },
  restaurantsBlog: {
    url: '/vendeglatohelyek',
    pathParam: 'idAndTitle',
    onlyWithPathParam: true,
    navbar: false,
    footer: false,
    submenus: [],
  },
  universityBlog: {
    url: '/tizparancsolat',
    pathParam: 'idAndTitle',
    onlyWithPathParam: true,
    navbar: false,
    footer: false,
    submenus: [],
  },
  universitySimpleBlog: {
    url: '/egyetem',
    pathParam: 'idAndTitle',
    onlyWithPathParam: true,
    navbar: false,
    footer: false,
    submenus: [],
  },
  impress: {
    url: '/impresszum',
    navbar: false,
    footer: true,
    submenus: [],
  },
  notfound: {
    url: '/404',
    navbar: false,
    footer: false,
    submenus: [],
  },
  cookie: {
    url: '/cookies-policy',
    navbar: false,
    footer: false,
    submenus: [],
  },
};

const calcPageLinks = () => {
  let urls = Object.keys(PAGES).map((currentPage) => {
    const page = PAGES[currentPage];
    let url = page.url;
    if (page.pathParam) {
      url += `/:${page.pathParam}`;
    }
    return url;
  });
  Object.keys(PAGES).map((currentPage) => {
    const page = PAGES[currentPage];
    if (page.pathParam && !page.onlyWithPathParam) {
      urls.push(page.url);
    }
  });
  return urls;
}

export const PAGE_LINKS = calcPageLinks();