import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { PAGES } from '../../datas/pages/pages';
import { SocialPages } from '../common/social-pages/social-pages';

export const Navbar = () => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => {
    setIsOpen(false);
  };

  const toogleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`component-navbar${isOpen ? ' open' : ''}`}>
      <nav className="bg-dark-green no-select pb-half pt-half pl pr">
        <div className="navbar-brand-wrapper">
          <NavLink
            to={PAGES.home.url}
            onClick={closeMenu}
            aria-label={intl.formatMessage({ id: 'title' })}
          >
            <div className="uniref-logo"></div>
          </NavLink>
        </div>
        <div className="navbar-pages-wrapper">
          <ul>
            {Object.keys(PAGES)
              .filter((page) => PAGES[page].navbar)
              .map((page) => (
                <li className="pl-2x pr-2x" key={page}>
                  <NavLink
                    to={PAGES[page].url}
                    isActive={(match, location) =>
                      match?.isExact ||
                      PAGES[page].submenus.includes(
                        `/${location.pathname.split('/')[1]}`
                      )
                    }
                  >
                    <FormattedMessage id={`pages.${page}`} />
                  </NavLink>
                </li>
              ))}
          </ul>
        </div>
        <div className="navbar-socials-wrapper">
          <SocialPages />
        </div>
        <div className="navbar-hamburger-wrapper">
          <div className="button-wrapper">
            <button
              onClick={toogleMenu}
              aria-label={intl.formatMessage({
                id: 'hamburgermenu',
              })}
            >
              <div />
              <div />
              <div />
            </button>
          </div>
        </div>
      </nav>
      <div className="navbar-mobilemenu-wrapper">
        <ul className="mb-2x">
          {Object.keys(PAGES)
            .filter((page) => PAGES[page].navbar)
            .map((page) => (
              <li className="pl-2x pr-2x" key={page}>
                <NavLink
                  className="h2"
                  to={PAGES[page].url}
                  isActive={(match, location) =>
                    match?.isExact ||
                    PAGES[page].submenus.includes(
                      `/${location.pathname.split('/')[1]}`
                    )
                  }
                  onClick={closeMenu}
                >
                  <FormattedMessage id={`pages.${page}`} />
                </NavLink>
              </li>
            ))}
        </ul>
        <div className="text-center">
          <SocialPages />
        </div>
      </div>
    </div>
  );
};
