export const calcUniversitiesInitialValues = (initialValues) => {
  let universities = {
    page: null,
    contents: null,
    allPages: null,
    error: false,
  };
  if (initialValues?.universities) {
    universities = {
      page: initialValues.universities.page,
      contents: initialValues.universities.contents,
      allPages: initialValues.universities.allPages,
      error: false,
    };
  } else if (typeof window === 'object' && window?.initialValues?.universities) {
    universities = {
      page: window.initialValues.universities.page,
      contents: window.initialValues.universities.contents,
      allPages: window.initialValues.universities.allPages,
      error: false,
    };
  }
  return universities;
};

export const calcUniversityInitialValues = (initialValues) => {
  let university = {
    id: null,
    structureId: null,
    content: null,
    error: false,
  };
  if (initialValues?.university) {
    university = {
      id: initialValues.university.id,
      structureId: initialValues.university.structureId,
      content: initialValues.university.content,
      error: false,
    };
  } else if (typeof window === 'object' && window?.initialValues?.university) {
    university = {
      id: window.initialValues.university.id,
      structureId: window.initialValues.university.structureId,
      content: window.initialValues.university.content,
      error: false,
    };
  }
  return university;
};