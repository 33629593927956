/**
 * Week in year.
 *
 * @param {Date} date selected Date
 */
export const getWeekInYear = (date) => {
  // Copy date so don't modify original
  const d = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return weekNo;
};

/**
 * Next month.
 *
 * @param {int} year selected year
 * @param {int} month selected month
 */
export const getNextMonth = (year, month) => {
  const date = new Date(year, month + 1, 0);
  date.setDate(date.getDate() + 1);
  return date;
};

/**
 * Previous month.
 *
 * @param {int} year selected year
 * @param {int} month selected month
 */
export const getPrevMonth = (year, month) => {
  const date = new Date(year, month - 1, 0);
  date.setDate(date.getDate() + 1);
  return date;
};

/**
 * Last day of selected month.
 *
 * @param {int} year selected year
 * @param {int} month selected month
 */
export const getLastDateOfMonth = (year, month) => {
  const date = new Date(year, month + 1, 0);
  return date.getDate();
};

/**
 * First day of the selected month.
 *
 * @param {int} year selected year
 * @param {int} month selected month
 */
export const getFirstDayInMonth = (year, month) => {
  const date = new Date(year, month, 1);
  return date.getDay() === 0 ? 7 : date.getDay();
};

/**
 * Last day of the selected month.
 *
 * @param {int} year selected year
 * @param {int} month selected month
 */
export const getLastDayInMonth = (year, month) => {
  const date = new Date(year, month + 1, 0);
  return date.getDay() === 0 ? 7 : date.getDay();
};

/**
 * Name of selected month.
 *
 * @param {int} month selected month
 * @param {string} locale current locale
 */
export const getMonthName = (month, locale) => {
  const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
  return formatter.format(new Date(2020, month, 1));
};

/**
 * Date string in calendar.
 *
 * @param {Date} date current date
 * @param {string} separator separator
 */
export const getFormatedCalendarDate = (date, separator = '-') => {
  return `${date.getFullYear()}${separator}${date.getMonth() < 9 ? '0' : ''}${
    date.getMonth() + 1
  }${separator}${date.getDate() < 10 ? 0 : ''}${date.getDate()}`;
};

/**
 * Date period start part string in calendar.
 *
 * @param {Date} startDate start date of period
 * @param {Date} endDate end date of period
 * @param {boolean} fullDay full day event if it is true
 * @param {string} locale current locale
 */
export const getFormatedPeriodDateStartPart = (startDate, endDate, fullDay, locale) => {
  const startYear = `${startDate.getFullYear()}.`;
  const startMonth = `${getMonthName(startDate.getMonth(), locale)}`;
  const startDay = `${
    startDate.getDate() < 10 ? 0 : ''
  }${startDate.getDate()}.`;
  const startTime = `${
    startDate.getHours() < 10 ? '0' : ''
  }${startDate.getHours()}:${
    startDate.getMinutes() < 10 ? '0' : ''
  }${startDate.getMinutes()}`;
  const endTime = `${endDate.getHours() < 10 ? '0' : ''}${endDate.getHours()}:${
    endDate.getMinutes() < 10 ? '0' : ''
  }${endDate.getMinutes()}`;
  if (fullDay || (startTime === '00:00' && endTime === '23:59')) {
    return `${startYear} ${startMonth} ${startDay}`;
  }
  return `${startYear} ${startMonth} ${startDay} ${startTime}`;
};

/**
 * Date period end part string in calendar.
 *
 * @param {Date} startDate start date of period
 * @param {Date} endDate end date of period
 * @param {boolean} fullDay full day event if it is true
 * @param {string} locale current locale
 */
export const getFormatedPeriodDateEndPart = (startDate, endDate, fullDay, locale) => {
  if (fullDay) {
    return null;
  }
  const endYear = `${endDate.getFullYear()}.`;
  const endMonth = `${getMonthName(endDate.getMonth(), locale)}`;
  const endDay = `${endDate.getDate() < 10 ? 0 : ''}${endDate.getDate()}.`;
  const startTime = `${
    startDate.getHours() < 10 ? '0' : ''
  }${startDate.getHours()}:${
    startDate.getMinutes() < 10 ? '0' : ''
  }${startDate.getMinutes()}`;
  const endTime = `${endDate.getHours() < 10 ? '0' : ''}${endDate.getHours()}:${
    endDate.getMinutes() < 10 ? '0' : ''
  }${endDate.getMinutes()}`;
  if (
    startDate.getFullYear() === endDate.getFullYear() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getDate() === endDate.getDate()
  ) {
    return `${endTime}`;
  }
  if (startTime === '00:00' && endTime === '23:59'){
    return `${endYear} ${endMonth} ${endDay}`;
  }
  return `${endYear} ${endMonth} ${endDay} ${endTime}`;
};

/**
 * Date period string in calendar.
 *
 * @param {Date} startDate start date of period
 * @param {Date} endDate end date of period
 * @param {boolean} fullDay full day event if it is true
 * @param {string} locale current locale
 */
export const getFormatedPeriodDate = (startDate, endDate, fullDay, locale) => {
  const startPart = getFormatedPeriodDateStartPart(startDate, endDate, fullDay, locale);
  const endPart = getFormatedPeriodDateEndPart(startDate, endDate, fullDay, locale);
  if (endPart === null){
    return startPart;
  }
  return `${startPart} - ${endPart}`;
};

/**
 * Name of days.
 *
 * @param {Object} locale
 * @param {boolean} shortFormat
 */
export const getNameOfDays = (locale, shortFormat = false) => {
  const dayFormatter = new Intl.DateTimeFormat(locale, {
    weekday: `${shortFormat ? 'short' : 'long'}`,
  });
  return [0, 1, 2, 3, 4, 5, 6].map((dayInWeek) =>
    dayFormatter.format(new Date(2020, 0, 6 + dayInWeek))
  );
};

/**
 * Check Date equals
 *
 * @param {Date} date1
 * @param {Date} date2
 * @param {number} deep az ellenorzes melysege
 */
export const equalsDate = (date1, date2, deep) => {
  return (
    date1 instanceof Date &&
    date2 instanceof Date &&
    date1.getFullYear() === date2.getFullYear() &&
    (deep < 2 || date1.getMonth() === date2.getMonth()) &&
    (deep < 3 || date1.getDate() === date2.getDate()) &&
    (deep < 4 || date1.getHours() === date2.getHours()) &&
    (deep < 5 || date1.getMinutes() === date2.getMinutes()) &&
    (deep < 6 || date1.getSeconds() === date2.getSeconds()) &&
    (deep < 7 || date1.getMilliseconds() === date2.getMilliseconds())
  );
};

export const getDiffBeetwenDate = (date1, date2) => {
  let diffTime = date1 - date2;
  if (diffTime > 0) {
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    diffTime -= diffDays * (1000 * 60 * 60 * 24);
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    diffTime -= diffHours * (1000 * 60 * 60);
    const diffMinutes = Math.floor(diffTime / (1000 * 60));
    diffTime -= diffMinutes * (1000 * 60);
    const diffSeconds = Math.floor(diffTime / 1000);
    return {
      days: `${diffDays < 10 ? '0' : ''}${diffDays}`,
      hours: `${diffHours < 10 ? '0' : ''}${diffHours}`,
      minutes: `${diffMinutes < 10 ? '0' : ''}${diffMinutes}`,
      seconds: `${diffSeconds < 10 ? '0' : ''}${diffSeconds}`,
    };
  } else {
    return {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  }
};
