export const calcBlogsInitialValues = (initialValues) => {
  let blogs = {
    page: null,
    structure: null,
    contents: null,
    allPages: null,
    error: false,
  };
  if (initialValues?.blogs) {
    blogs = {
      page: initialValues.blogs.page,
      contents: initialValues.blogs.contents,
      allPages: initialValues.blogs.allPages,
      error: false,
    };
  } else if (typeof window === 'object' && window?.initialValues?.blogs) {
    blogs = {
      page: window.initialValues.blogs.page,
      contents: window.initialValues.blogs.contents,
      allPages: window.initialValues.blogs.allPages,
      error: false,
    };
  }
  return blogs;
};

export const calcBlogInitialValues = (initialValues) => {
  let blog = {
    id: null,
    structureId: null,
    content: null,
    error: false,
  };
  if (initialValues?.blog) {
    blog = {
      id: initialValues.blog.id,
      structureId: initialValues.blog.structureId,
      content: initialValues.blog.content,
      error: false,
    };
  } else if (typeof window === 'object' && window?.initialValues?.blog) {
    blog = {
      id: window.initialValues.blog.id,
      structureId: window.initialValues.blog.structureId,
      content: window.initialValues.blog.content,
      error: false,
    };
  }
  return blog;
};
