import React from 'react';
import PropTypes from 'prop-types';

const AVAILABLE_COL_VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const Col = ({ className, col, xs, sm, md, lg, xl, xxl, children }) => {
  let classes = '';
  if (className) {
    classes += `${className} `;
  }
  if (col) {
    classes += `col-${col} `;
  }
  if (xs) {
    classes += `col-xs-${xs} `;
  }
  if (sm) {
    classes += `col-sm-${sm} `;
  }
  if (md) {
    classes += `col-md-${md} `;
  }
  if (lg) {
    classes += `col-lg-${lg} `;
  }
  if (xl) {
    classes += `col-xl-${xl} `;
  }
  if (xxl) {
    classes += `col-xxl-${xxl} `;
  }
  return <div className={classes}>{children}</div>;
};

Col.prototype = {
  /**
   * Extra class in col
   */
  className: PropTypes.string,
  /**
   * col default
   */
  col: PropTypes.oneOf(AVAILABLE_COL_VALUES),
  /**
   * col xs
   */
  xs: PropTypes.oneOf(AVAILABLE_COL_VALUES),
  /**
   * col sm
   */
  sm: PropTypes.oneOf(AVAILABLE_COL_VALUES),
  /**
   * col md
   */
  md: PropTypes.oneOf(AVAILABLE_COL_VALUES),
  /**
   * col lg
   */
  lg: PropTypes.oneOf(AVAILABLE_COL_VALUES),
  /**
   * col xl
   */
  xl: PropTypes.oneOf(AVAILABLE_COL_VALUES),
  /**
   * col xxl
   */
  xxl: PropTypes.oneOf(AVAILABLE_COL_VALUES),
  /**
   * Content in col
   */
  children: PropTypes.element.isRequired,
};
